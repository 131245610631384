import React, { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import {
  Form,
  Button,
  TextFieldControlled as Input,
} from '@leafwell/components';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useBrazeContext } from 'contexts/braze-context';
import { useEventsTracker } from 'contexts/eventsTracker';

type DownloadablesFormProps = {
  formType: string;
};

export default function DownloadablesForm({
  formType,
}: DownloadablesFormProps) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const posthog = usePostHog();
  const { trackEvent } = useEventsTracker();
  const { saveUser: saveBrazeUser } = useBrazeContext();
  const { translations } = useWordPressContext();

  async function handleSubmit({ email }) {
    // https://posthog.com/docs/product-analytics/identify#alias-assigning-multiple-distinct-ids-to-the-same-user
    posthog.alias(email);

    saveBrazeUser({ email });

    trackEvent({
      event: 'form_submit',
      form_name: `formType_${window?.location?.pathname}`,
      form_destination: window?.location?.pathname,
      form_submit_text: translations['Download'],
      formType,
    });

    setFormSubmitted(true);
  }

  return (
    <Form
      className="gap-y-4"
      mode="onSubmit"
      defaultValues={{
        email: '',
      }}
      onSubmit={handleSubmit}
    >
      {formSubmitted ? (
        <p>Thanks! Check your inbox for details.</p>
      ) : (
        <>
          <Input
            className="rounded-lg"
            name="email"
            placeholder="Email"
            type="email"
            validations={['required', 'email']}
          />
          <Button
            type="submit"
            label={translations['Download']}
            name="submit"
            variant="secondary"
            className="w-full rounded-lg"
          />
        </>
      )}
    </Form>
  );
}
