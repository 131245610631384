import React, { useEffect, useState } from 'react';
import type { Media } from 'types/media';

import { useRouter } from 'next/router';

import camelcaseKeys from 'camelcase-keys';
import { request } from 'lib/rest';
import Figure from './figure';
import WysiwygContent from './wysiwyg-content';

type PartnerProps = {
  title: string;
  logo: Media;
  website: string;
  customMessage?: string;
};

const PartnerCustomData: React.FC = () => {
  const { query } = useRouter();
  const [partnerData, setPartnerData] = useState<PartnerProps | null>(null);

  useEffect(() => {
    if (query.utm_source && !partnerData) {
      // call api to fetch partner data based on `utm_source`
      request(`/partner?slug=${query.utm_source}`).then(res => {
        if (res.data && res.data?.totalItems > 0) {
          const { title, acf } = camelcaseKeys(res.data.items[0], {
            deep: true,
          });
          const { logo, website, customMessage } = acf;

          if (customMessage.length) {
            setPartnerData({
              title,
              logo,
              website,
              customMessage,
            });
          }
        }
      });
    }
  }, [query]);

  const Wrapper = partnerData?.website ? 'a' : 'div';
  const wrapperProps = partnerData?.website
    ? {
        href: partnerData?.website,
        target: '_blank',
      }
    : {};

  return partnerData ? (
    <div className="flex gap-4 -mt-4 mb-8 items-center">
      <Wrapper {...wrapperProps}>
        <Figure
          {...partnerData?.logo}
          width={100}
          height={100}
          alt={partnerData?.logo?.alt}
          className=""
          objectFit="contain"
        />
      </Wrapper>
      {partnerData?.customMessage?.length > 0 ? (
        <WysiwygContent content={partnerData?.customMessage} />
      ) : null}
    </div>
  ) : null;
};

export default PartnerCustomData;
