export const restBaseUrl = `${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/wp-json/wp/v2`;
export const restHeaders = {
  Authorization: `Basic ${process.env.WP_ADMIN_SECRET}`,
  'Content-Type': 'application/json',
  'Accept-Language': 'en',
};

export type FetchDataResult<T> = {
  data?: {
    items: T;
    totalItems?: number;
  };
  error?: {
    message: string;
    status: number;
  };
};

export async function request<T>(
  path: string,
  options?: RequestInit,
): Promise<FetchDataResult<T>> {
  try {
    const endpoint = `${restBaseUrl}${path}`;
    const response = await fetch(endpoint, {
      headers: {
        ...restHeaders,
        ...options?.headers,
      },
    });

    if (response.ok) {
      const totalItems = Number(response.headers.get('x-wp-total'));
      const data: T = await response.json();

      return {
        data: {
          items: data,
          totalItems,
        },
      };
    } else {
      const { message, data } = await response.json();

      return {
        error: {
          message,
          status: data.status,
        },
      };
    }
  } catch (e) {
    return {
      error: {
        message: 'Something went wrong',
        status: 500,
      },
    };
  }
}
