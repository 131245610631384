import React from 'react';
import {
  Heading as HeadingComponent,
  HeadingProps,
} from '@leafwell/components';

import TextHighlight from './text-highlight';

export type HeadingBodyProps = Omit<HeadingProps, 'children'> & {
  className?: string;
  title: string;
};

const HeadingBody: React.FC<HeadingBodyProps> = ({
  className = '',
  title,
  ...rest
}) => {
  return (
    <HeadingComponent
      className={['font-sans', 'my-reset prose', className].join(' ')}
      {...rest}
    >
      <TextHighlight text={title} />
    </HeadingComponent>
  );
};

export default HeadingBody;
